import Bsc from 'assets/BSC.svg'
import Neo from 'assets/Neo.svg'
import SWTH from 'assets/SWTH-token.svg'
import MobileWarningCard from "components/Common/MobileWarningCard"
import { MigrateChoice } from "constants/migrate"
import dayjs from 'dayjs'
import React from "react"
import { useNavigate } from 'react-router-dom'
import './MigratePage.css'
import MigrationCard from './MigrationCard'

let bsc_tweet = <a className='card-description theme-color underline' href={'https://mobile.twitter.com/0xcarbon/status/1493514798939930625'} target="_blank" rel="noopener noreferrer">this tweet</a>
let SWH_upgraded_blog_post = <a className='card-description theme-color underline' href={'https://medium.com/switcheo/swh-swth-token-swap-39342759c18'} target="_blank" rel="noopener noreferrer">blog post</a>

const bsc_migration =
  <span>The BSC V1 to V2 migration is now live! Check out {bsc_tweet} to learn more.</span>;

const neo_inflationary_migration =
  <span>The upgrade for SWTH (Inflationary) to Neo N3 live! Native migration is coming to CarbonHub soon. For now, migrate to Neo3 SWTH on Demex.  </span>
  ;

const neo_legacy_migration =
  <span>The upgrade for SWTH (Legacy) to Neo N3 is live! Native migration is coming to CarbonHub soon. For now, migrate to Neo3 SWTH on Demex.</span>
  ;

const swh_migration =
  <span>In 2018, SWH was upgraded to SWTH to enhance Carbon’s cross-chain capabilities. To learn more, read this {SWH_upgraded_blog_post}.</span>
  ;

const MigratePage: React.FC = () => {
  const navigate = useNavigate();
  const endDate = dayjs.utc('2025-04-30')
  const migrationEnded = dayjs.utc().isAfter(endDate)

  return (
    <div className="migratePage-wrapper theme-color">
      <div className="migratePage-inner-wrapper">
        <div className="top-text">
          <p className='section-header'>SWTH Token Migration</p>
          <p className='section-subheader'>
            Blockchains occasionally undergo upgrades for a myriad of reasons, including enhancing protocol security, improving more powerful features and supporting new architecture. </p>
          <p className='section-text'>
            To ensure the tokens you have are compatible with the new upgrades, you will need to migrate your old tokens to the new versions. Check out the past and on-going SWTH token migrations available.   </p>
        </div>
        <p className='section-subpoint-header'> Live and Upcoming Migrations </p>
        <div className="migrate-callout-wrapper" style={{ width: "100%" }}>
          <MobileWarningCard type="Neutral" text="Please head to the desktop version to migrate your tokens." style={{ width: "100%" }} />
        </div>
        <div className='migration-section'>
          <div className='migration-card'>
            <MigrationCard
              title="BSC v1 to v2"
              logo={Bsc}
              description={bsc_migration}
              start='15 Feb, 2022'
              end={endDate.format('DD MMM YYYY')}
              buttonText={migrationEnded ? 'Ended' : 'Migrate'}
              oldAddress="0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c"
              newAddress="0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468"
              oldLink="https://bscscan.com/token/0x250b211ee44459dad5cd3bca803dd6a7ecb5d46c"
              newLink="https://bscscan.com/token/0xC0ECB8499D8dA2771aBCbF4091DB7f65158f1468"
              setMigrate={migrationEnded ? undefined : () => navigate(
                `/migrate/swth-token-migration`,
                { state: MigrateChoice.BSC }
              )} />
          </div>
          <div className='migration-card'>
            <MigrationCard
              title="Neo (SWTH Inflationary) to Neo N3"
              logo={Neo}
              description={neo_inflationary_migration}
              start='24 Dec, 2021'
              end={endDate.format('DD MMM YYYY')}
              buttonText={migrationEnded ? 'Ended' : 'Migrate'}
              oldAddress="0x39a154ae9ad537a18e8eec2f1a40ebee02e6093e"
              newAddress="0x78e1330db47634afdb5ea455302ba2d12b8d549f"
              oldLink="https://neotube.io/nep5/3e09e602eeeb401a2fec8e8ea137d59aae54a139/page/1"
              newLink="https://neo3.neotube.io/tokens/nep17/0x78e1330db47634afdb5ea455302ba2d12b8d549f"
              setMigrate={migrationEnded ? undefined : () => navigate(
                `/migrate/swth-token-migration`,
                { state: MigrateChoice.NeoInflationary }
              )} />
          </div>
          <div className='migration-card'>
            <MigrationCard
              title="Neo (SWTH Legacy) to Neo N3"
              logo={Neo}
              description={neo_legacy_migration}
              start='22 Oct, 2020'
              end={endDate.format('DD MMM YYYY')}
              buttonText={migrationEnded ? 'Ended' : 'Migrate'}
              oldAddress="0x32e125258b7db0a0dffde5bd03b2b859253538ab"
              newAddress="0x78e1330db47634afdb5ea455302ba2d12b8d549f"
              oldLink="https://neotube.io/nep5/ab38352559b8b203bde5fddfa0b07d8b2525e132/page/1"
              newLink="https://neo3.neotube.io/tokens/nep17/0x78e1330db47634afdb5ea455302ba2d12b8d549f"
              setMigrate={migrationEnded ? undefined : () => navigate(
                `/migrate/swth-token-migration`,
                { state: MigrateChoice.NeoLegacy }
              )} />
          </div>
        </div>
        <p className='section-subpoint-header' style={{ paddingTop: 20 }}> Past Migrations </p>
        <div className='migration-section'>
          <div className='migration-card'>
            <MigrationCard
              title="SWH to SWTH"
              logo={SWTH}
              description={swh_migration}
              start='14 May, 2018'
              end='27 Sep, 2019'
              buttonText='Ended'
              oldAddress="0xdd2b538e2a0c1db1ae5061c15be14f916bd1e678"
              newAddress="0x32e125258b7db0a0dffde5bd03b2b859253538ab"
              oldLink="https://neotube.io/nep5/78e6d16b914fe15bc16150aeb11d0c2a8e532bdd/page/1"
              newLink="https://neotube.io/nep5/ab38352559b8b203bde5fddfa0b07d8b2525e132/page/1" />
          </div>
        </div>
      </div>
    </div>);
};

export default MigratePage;
